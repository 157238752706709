define(['app'], function(app) {

  

  var globalComponentsShowcase = function() {
    var self = this;
    self.element = null;

    var _config = {
      anchorLinkContainer: '[data-js-element=anchorLinkContainer]',
      componentTitleId: 'id',
      listItemElement: 'a',
      hrefHash: '#',
      listItemClass: 'globalComponentsShowcase_listItem',
      componentTitleSelector: '[data-js-element=componentTitle]'
    };

    var _init = function(element) {
      self.element = element;
      var componentTitles = self.element.querySelectorAll(self.config.componentTitleSelector);
      var anchorLinkContainer = self.element.querySelector(self.config.anchorLinkContainer);

      if (anchorLinkContainer) {
        for (var i = 0, l = componentTitles.length; i < l; i++) {
          var anchorLinkCreated = self.createAnchorLink(componentTitles[i]);
          anchorLinkContainer.appendChild(anchorLinkCreated);
        }
      }

      return self;
    };

    var _createAnchorLink = function(componentTitle) {
      var anchorLink = document.createElement(self.config.listItemElement);
      anchorLink.innerText = componentTitle.innerText;
      anchorLink.href = self.config.hrefHash + app.element.getAttribute(self.config.componentTitleId, componentTitle);
      app.element.addClass(self.config.listItemClass, anchorLink);
      return anchorLink;
    };

    self.config = _config;
    self.init = _init;
    self.createAnchorLink = _createAnchorLink;
  };

  return globalComponentsShowcase;
});
